/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { getAllCohorts } from '../../collections/cohort';
import { getAllCompanies } from '../../collections/company';
import { getUserById, updateUser } from '../../collections/user';
import { getAccountById, updateAccount } from '../../collections/account';
import { setUserEmail } from '../../firebase/firebaseConfig';
import { useAppContext } from '../../contexts/appContext';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Skeleton from 'react-loading-skeleton';
import { UserType, UserTypeNew } from '../../modeltypes/user';
import { AccountRole, AccountType, AccountTypeBuilder } from '../../modeltypes/account';
import { userTypeNewSchema } from '../../modelschemas/user';
import { accountTypeBaseSchema } from '../../modelschemas/account';
import { dieIfNullOrUndef } from '../../utility/GeneralUtilities';
import { CompanyType } from '../../modeltypes/company';
import { CohortType } from '../../modeltypes/cohort';
import { toast } from 'react-toastify';

const UserForm = () => {
  type CohortSelect = {
    label: string;
    value: CohortType;
  };

  type CompanySelect = {
    label: string;
    value: CompanyType;
  };

  const [account, setAccount] = useState<AccountType | null>(null);
  const [user, setUser] = useState<UserType | null>(null);
  const [cohorts, setCohorts] = useState<CohortSelect[]>([]);
  const [companies, setCompanies] = useState<CompanySelect[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [startingEmail, setStartingEmail] = useState<string>('');

  const availableRoles = [
    {
      label: AccountRole.User,
      value: AccountRole.User,
    },
    {
      label: AccountRole.Coach,
      value: AccountRole.Coach,
    },
    {
      label: AccountRole.Science,
      value: AccountRole.Science,
    },
    {
      label: AccountRole.Audio,
      value: AccountRole.Audio,
    },
    {
      label: AccountRole.Admin,
      value: AccountRole.Admin,
    },
    {
      label: AccountRole.Marketing,
      value: AccountRole.Marketing,
    },
  ];

  const { id } = useParams();
  const navigate = useNavigate();
  const { setBreadcrumbPaths } = useAppContext();

  const { values, errors, handleSubmit, handleChange, setValues } = useFormik<{
    firstName: string;
    lastName: string;
    cohort: string;
    companyId: string;
    email: string;
    role: AccountRole;
    legacyAccount: boolean;
    isTestUser: boolean;
  }>({
    initialValues: {
      firstName: '',
      lastName: '',
      cohort: '',
      companyId: '',
      email: '',
      role: AccountRole.User,
      legacyAccount: false,
      isTestUser: false,
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required('Name is required'),
      lastName: yup.string(),
      cohort: yup.string(),
      companyId: yup.string(),
      email: yup.string().email('Correct email is required'),
      role: yup.string(),
      legacyAccount: yup.boolean().required(),
    }),
    onSubmit: (values) => {
      const updateData = async (newUser: UserTypeNew, newAccount?: AccountTypeBuilder) => {
        if (!newUser.id) {
          return false;
        }
        if (user?.id) {
          // This is a cleanup effort. Once clean we shouldn't need this.
          // noinspection SuspiciousTypeOfGuard
          if (typeof user.age === 'string') {
            try {
              user.age = parseInt(user.age);
              newUser.age = user.age;
            } catch {
              delete user['age'];
              delete newUser['age'];
            }
          }
          const idUser: UserType = {
            ...newUser,
            id: user.id,
          };
          newUser.id = user.id;
          await updateUser(user.id, idUser);
        }
        if (newAccount) {
          await updateAccount(dieIfNullOrUndef(account?.id), newAccount);

          try {
            if (newAccount?.email && newAccount?.email !== startingEmail) {
              await setUserEmail({
                id: newUser.id,
                email: newAccount.email,
              });
            }
          } catch {
            console.log('Error setting claims/ email');
          }
        }
        return true;
      };
      const userDefaults = userTypeNewSchema.parse({});
      const newUser: UserTypeNew = {
        ...userDefaults,
        ...user,
        firstName: values.firstName,
        lastName: values.lastName,
        legacyUser: false,
      };
      const accountDefaults = accountTypeBaseSchema.parse({});
      let newAccount: AccountType | undefined = undefined;
      if (account) {
        newAccount = {
          ...accountDefaults,
          ...account,
          companyId: values?.companyId || null,
          cohort: values?.cohort || null,
          email: values.email,
          role: values.role,
          isTestUser: values.isTestUser,
          legacyAccount: values.legacyAccount,
        };
      } else if (user && user.id) {
        console.warn(`User ID: ${user.id} has no corresponding Account record, and is effectively useless. Just FYI.`);
      }

      if (user && user.id) {
        toast
          .promise(updateData(newUser, newAccount), {
            pending: `Updating ${newAccount?.email}...`,
            error: "Can't do it now, try again.",
            success: `Updated ${newAccount?.email}!`,
          })
          .then((b) => {
            if (b) navigate('/Users');
          });
      }
    },
  });

  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'Users',
        path: '/users',
      },
      {
        label: id === 'new' ? 'New User' : 'Edit User',
        path: `/users/${id}`,
      },
    ]);
  }, [id, setBreadcrumbPaths]);

  useEffect(() => {
    const getData = async () => {
      const allCohorts = await getAllCohorts();
      setCohorts(
        allCohorts.map((c) => {
          return {
            label: c.name || '',
            value: c,
          };
        }),
      );
      const allCompanies = await getAllCompanies();
      setCompanies(
        allCompanies
          .filter((c) => c.companyName)
          .map((c) => {
            return {
              label: c.companyName || '',
              value: c,
            };
          }),
      );

      if (id !== 'new') {
        const currUser = await getUserById(id || null);

        if (currUser !== null) {
          setUser(currUser);
          const currAccount = await getAccountById(id || null);
          setAccount(currAccount);
          setStartingEmail(currAccount?.email || '');
          // const currCohort = allCohorts.find((c) => c.id === currAccount?.cohort);
          // const currCompany = allCompanies.find((c) => c.id === currAccount?.companyId);
          await setValues({
            firstName: currUser?.firstName || '',
            lastName: currUser?.lastName || '',
            role: currAccount?.role as AccountRole,
            companyId: currAccount?.companyId || '',
            cohort: currAccount?.cohort || '',
            email: currAccount?.email || '',
            legacyAccount: currAccount?.legacyAccount || false,
            isTestUser: currAccount?.isTestUser || false,
          });
        }
      }
    };
    getData().then(() => setIsLoading(false));
  }, [id, setValues]);

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton height={50} />
          <Skeleton count={15} className='mt-3' />{' '}
        </>
      ) : (
        <form className='space-y-8 divide-y divide-gray-200' onSubmit={handleSubmit}>
          <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
            <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='firstname' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  First Name
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <div className='max-w-lg flex rounded-md shadow-sm'>
                    <input
                      type='text'
                      name='firstName'
                      id='firstName'
                      autoComplete='firstname'
                      className={`flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                        errors.firstName ? 'border-red-300' : 'border-gray-300'
                      }`}
                      defaultValue={values.firstName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='firstname' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Last Name
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <div className='max-w-lg flex rounded-md shadow-sm'>
                    <input
                      type='text'
                      name='lastName'
                      id='lastName'
                      autoComplete='lastname'
                      className={`flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                        errors.lastName ? 'border-red-300' : 'border-gray-300'
                      }`}
                      defaultValue={values.lastName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='firstname' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  E-mail
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <div className='max-w-lg flex rounded-md shadow-sm'>
                    <input
                      type='text'
                      name='email'
                      id='email'
                      autoComplete='email'
                      className={`flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ${
                        errors.email ? 'border-red-300' : 'border-gray-300'
                      }`}
                      defaultValue={values.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='incentives' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Company
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <Select
                    isClearable
                    options={companies}
                    value={companies.find((c) => c.value.id === values.companyId)}
                    onChange={(val) => {
                      if (!cohorts.find((c) => values.cohort === c.value.id && c.value.company === val?.value.id)) {
                        setValues({
                          ...values,
                          cohort: val?.value.id || '',
                        });
                      }
                      setValues({
                        ...values,
                        companyId: val?.value.id || '',
                        cohort: '',
                      });
                    }}
                  />
                </div>
              </div>

              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='incentives' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Cohort
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <Select
                    isDisabled={!values.companyId}
                    isClearable
                    options={cohorts.filter((c) => c.value.company === values.companyId)}
                    value={cohorts.find((c) => c.value.id === values.cohort)}
                    onChange={(val) => {
                      setValues({
                        ...values,
                        cohort: val?.value.id || '',
                      });
                    }}
                  />
                </div>
              </div>

              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='incentives' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Role
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <Select
                    options={availableRoles.filter((role) => role.value !== AccountRole.Coach)}
                    value={availableRoles.find((r) => r.value === values.role)}
                    onChange={(val) => {
                      setValues({
                        ...values,
                        role: val?.value || AccountRole.User,
                      });
                    }}
                  />
                </div>
              </div>

              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='incentives' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Legacy User
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <input
                    type={'checkbox'}
                    checked={values.legacyAccount}
                    onChange={handleChange}
                    name='legacyAccount'
                    id='legacyAccount'
                  />
                </div>
              </div>
              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='incentives' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Is test user
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <input
                    type={'checkbox'}
                    checked={values.isTestUser}
                    onChange={handleChange}
                    name='isTestUser'
                    id='isTestUser'
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='pt-5'>
            <div className='flex justify-end'>
              <button
                type='button'
                className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                onClick={() => navigate('/sessions')}
              >
                Cancel
              </button>
              <button
                type='submit'
                className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                {'Update'}
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default UserForm;
