import { AudioNodeId, TagId } from './id';
import { HasCreateUpdate, HasId, WithRequired } from './shared';
import { getEnumFromString } from '../utility/GeneralUtilities';
import { SwitchOption } from '../utility/ScriptModel';

export enum TrackType {
  DECISION = 'DECISION',
  STORY = 'STORY',
  H_CHECK = 'H_CHECK',
  CHOOSE_PRACTICE = 'C_PRACTICE',
  SWITCH = 'SWITCH',
  QUIZ = 'QUIZ',
  STRESS_METER = 'STRESS_METER',
  MENTOR = 'MENTOR',
}

export enum SwitchType {
  GOALS = 'GOALS',
  EXPERIENCE = 'EXPERIENCE',
}

export enum HomeworkStatus {
  DONE = 'DONE',
  NOT_DONE = 'NOT DONE',
}

export type AudioNodeType = HasId<AudioNodeId> &
  HasCreateUpdate & {
    title?: string | null;
    code?: string | null;
    summaryText?: string | null;
    duration?: number | null;
    spokenText?: string | null;
    singleTap?: string | null;
    doubleTap?: string | null;
    tripleTap?: string | null;
    singleTapTagId?: TagId | null;
    mlAttribute?: TagId | null;
    doubleTapTagId?: TagId | null;
    tripleTapTagId?: TagId | null;
    nextSubsection?: string | null;
    // It's not clear that "naming" paths is actually meaningful or helpful, but...
    path?: string | null;
    pathCode?: string | null;
    silenceDuration?: number | null;
    silenceFixedLen?: boolean | null;
    comments?: string[];
    importedFromGDocID?: string | null;
    nodeType?: TrackType | null; // 'H_CHECK' | 'DECISION' | 'STORY' | 'C_PRACTICE' | null
    audioUrl?: string | null;
    homeworkStatus?: HomeworkStatus | null;
    homeworkAnswer?: string | null;
    switchOptions?: Array<SwitchOption> | null;
    subType?: SwitchType | null; // 'GOALS' | ..
    shouldCheckHomework?: boolean | null;
    toCheck?: boolean | null;
    audioNodeType?: string | null;
  };

// Base == Everything is optional.
export type AudioNodeTypeBase = Partial<AudioNodeType>;

// Builder == Everything except `id` is optional.
export type AudioNodeTypeBuilder = WithRequired<AudioNodeTypeBase, 'id'>;

// New == Everything is as normal, except that `id` is optional (because the DB will fill it)
export type AudioNodeTypeNew = Omit<AudioNodeType, 'id'> & Partial<Pick<AudioNodeType, 'id'>>;

export function trackTypeFromString(instr: string | undefined, throwOnError = true): TrackType | undefined {
  console.log('i', instr);
  const rv: TrackType | undefined = getEnumFromString(TrackType, instr ? instr : '');
  if (rv === undefined && throwOnError) {
    throw new Error(`Invalid value '${instr}' for conversion to TrackType.`);
  }
  return rv as TrackType | undefined;
}

export function switchTypeFromString(instr: string | undefined, throwOnError = true): SwitchType | undefined {
  const rv: SwitchType | undefined = getEnumFromString(SwitchType, instr ? instr : '');
  if (rv === undefined && throwOnError) {
    throw new Error(`Invalid value '${instr}' for conversion to SwitchType.`);
  }
  return rv as SwitchType | undefined;
}
