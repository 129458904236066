// *******************************************************
// LessonAnalysisForUsersWhoCompletedEverything
// -------------------------------------------------------
// This is a Screen for showing list all LessonRatings
// We want to understand who is getting communcation and are they responding to it.

// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import { format } from 'date-fns';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { differenceInDays } from 'date-fns';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------
// import { getAllLessonRatings } from 'src/collections/lessonRating';
import { getAllUnits } from 'src/collections/units';
import { getAllTracks } from 'src/collections/tracks';

// import { AnalyticsType } from 'src/modeltypes/analytics';
import { LessonRatingType } from 'src/modeltypes/lessonRating';
import { UnitType } from 'src/modeltypes/unit';
import { TrackType, TrackUnitType } from 'src/modeltypes/tracks';
// import { useNavigate } from 'react-router-dom';
import { getAllLessonRatings } from 'src/collections/lessonRating';
import BarChart from 'src/components/BarChart';
import LessonAnalysisPopupRating, { RatingPopupDataType } from './LessonAnalysisPopupRating';
import { getUserIdsWhoHaveComplededEverything } from 'src/collections/userJourney';
import { getUsersByIdArray } from 'src/collections/user';
// import { VictoryBar, VictoryChart, VictoryGroup, VictoryPie } from 'victory';
// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------
function getStandardDeviation(array: number[]) {
  const n = array.length;
  const mean = array.reduce((a, b) => a + b) / n;
  return Math.sqrt(array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n);
}

const LessonAnalysisForUsersWhoCompletedEverything = () => {
  // const LessonAnalysis = ({ analytics }: { analytics: AnalyticsType[] | undefined }) => {
  // const [lessonRatings, setLessonRatings] = useState<LessonRatingType[] | null>(null);
  // const navigate = useNavigate();

  const [lessonRatingsTotal, setLessonRatingsTotal] = useState<LessonRatingType[] | null>(null);
  const [sortedLessonRatings, setSortedLessonRatings] = useState<LessonRatingType[] | null>(null);
  const [lessonRatingByUnit, setLessonRatingByUnit] = useState<{ [unitId: string]: LessonRatingType[] } | null>(null);
  const [averageLessonRatingByUnit, setAverageLessonRatingByUnit] = useState<{ [unitId: string]: number[] } | null>(
    null,
  );

  const [averageLessonRatingByUnitValues, setAverageLessonRatingByUnitValues] = useState<
    { x: string; y: number; id: string; isV2: boolean }[] | null
  >(null);

  const [standardDevLessonRatingByUnitValues, setStandardDevLessonRatingByUnitValues] = useState<
    { x: string; y: number; id: string; isV2: boolean }[] | null
  >(null);

  const [sampleNumberFoLessonrRatingByUnitValues, setSampleNumberFoLessonRatingByUnitValues] = useState<
    { x: string; y: number; id: string; isV2: boolean }[] | null
  >(null);

  // console.log('averageLessonRatingByUnit: ', averageLessonRatingByUnit);
  const [units, setUnits] = useState<UnitType[] | null>(null);
  const [currentButton, setCurrentButton] = useState<string | null>('thisMonth');
  const [tracks, setTracks] = useState<TrackType[] | null>(null);
  const [introTracks, setIntroTracks] = useState<TrackUnitType[] | null>(null);
  const [userIds, setUserIds] = useState<string[] | null>(null);

  console.log('introTracks', introTracks);

  // console.log('introTracks: ', introTracks);
  const [postIntroTracks, setPostIntroTracks] = useState<TrackType[] | null>(null);
  const [showRating, setShowRating] = useState<boolean>(false);
  const [popupData, setPopupData] = useState<RatingPopupDataType>({
    chartData: null,
    chartTickData: null,
    unitData: null,
  });

  const today = new Date();

  const buttons: { name: string; id: string }[] = [
    {
      name: 'This Month',
      id: 'thisMonth',
    },
    {
      name: 'This Week',
      id: 'thisWeek',
    },
    {
      name: 'By Tracks',
      id: 'byTracks',
    },
    {
      name: 'By Pretracks',
      id: 'byPreTracks',
    },
    {
      name: 'Unit Averages By Tracks',
      id: 'averageByTracks',
    },
    {
      name: 'Unit Averages For intro Tracks',
      id: 'averageForIntroTracks',
    },
    {
      name: 'Unit Averages For post intro Tracks',
      id: 'averageForPostIntroTracks',
    },

    // {
    //   name: 'Unit Averages By Pre Tracks',
    //   id: 'averageByPreTracks',
    // },
  ];

  const butonnsS: { name: string; id: string; isV2: boolean }[] = useMemo(() => {
    let thisData: { name: string; id: string; isV2: boolean }[] = [];
    if (lessonRatingByUnit && Object.keys(lessonRatingByUnit)?.length && units?.length) {
      thisData = Object.keys(lessonRatingByUnit).map((x) => {
        const name = !units?.length ? x : units?.find((t2: UnitType) => x.includes(t2.id))?.title || x;
        const isV2 = !units?.length && units?.find((t2: UnitType) => x.includes(t2.id)) ? true : false;

        return {
          name,
          id: x,
          isV2,
        };
      });
    }
    return thisData;
  }, [lessonRatingByUnit, units]);

  const getUsers = async () => {
    const theseJourneys = await getUserIdsWhoHaveComplededEverything();
    const preproccessedUserIds = theseJourneys.map((journey) => journey.id);

    const users = await getUsersByIdArray(preproccessedUserIds);

    const usersWeWant = users.filter((user) => {
      // We want them to have an age
      // const theyHaveAnAge = (user?.age || 0) > 0;

      // const theHaveWants = user?.wants?.length;

      const notTestAccount = ![user?.email, user?.firstName, user?.lastName].some(
        (x) => x?.includes('test') || x?.includes('Test'),
      );

      // return theyHaveAnAge && theHaveWants && notTestAccount;
      return notTestAccount;
    });
    // console.log('usersWeWant.length ', usersWeWant.length);
    const userIds = usersWeWant?.map((user) => user?.id);

    // console.log('asdfasdf userIds: ', userIds);
    return userIds;
  };

  const preTrackItems: { name: string; id: string; lesson: string; isV2?: boolean }[] | null = useMemo(() => {
    let thisData: { name: string; id: string; isV2: boolean; lesson: string }[] = [];
    if (lessonRatingByUnit && Object.keys(lessonRatingByUnit)?.length && units?.length) {
      const filteredPreTrack = Object.keys(lessonRatingByUnit).filter(
        (x) => !units?.find((t2: UnitType) => x.includes(t2.id)),
      );

      thisData =
        filteredPreTrack.map((x) => {
          const name = x;
          const isV2 = false;
          const lesson = x?.split('_')?.pop() || 'Not Known';

          return {
            name,
            id: x,
            isV2,
            lesson,
          };
        }) || [];
    }
    return thisData;
  }, [lessonRatingByUnit, units]);

  const data = useMemo(() => {
    let statsData: LessonRatingType[] | null = [];

    let chartData:
      | {
          x: string;
          y: number;
        }[]
      | null = [];

    let chartTickData: string[] | null = [];
    let unitData: { name: string; id: string; isV2: boolean } | null = null;

    if (lessonRatingsTotal?.length && sortedLessonRatings?.length) {
      if (currentButton === 'thisMonth') {
        statsData = lessonRatingsTotal
          ?.filter((x) => x?.createdAt?.toDate && differenceInDays(today, x?.createdAt?.toDate()) <= 31)
          .sort((a, b) => {
            const date1 = a?.createdAt && a?.createdAt?.toDate ? a?.createdAt?.toDate() : new Date();
            const date2 = b?.createdAt && b?.createdAt?.toDate ? b?.createdAt?.toDate() : new Date();
            if (date1 && date2) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              return date2 - date1;
            }
            return -1;
          });
      } else if (currentButton === 'thisWeek') {
        statsData = lessonRatingsTotal
          ?.filter((x) => x?.createdAt?.toDate && differenceInDays(today, x?.createdAt?.toDate()) <= 7)
          .sort((a, b) => {
            const date1 = a?.createdAt && a?.createdAt?.toDate ? a?.createdAt?.toDate() : new Date();
            const date2 = b?.createdAt && b?.createdAt?.toDate ? b?.createdAt?.toDate() : new Date();
            if (date1 && date2) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              return date2 - date1;
            }
            return -1;
          });
      } else if (currentButton === 'averageForPostIntroTracks') {
        const byTrack = averageLessonRatingByUnitValues
          ?.filter(
            (x) => postIntroTracks && postIntroTracks.some((track) => track.units.some((unit) => unit?.id === x?.id)),
          )
          .sort((a, b) => b.y - a.y);

        // console.log('postIntroSeriesTracks: ', byTrack);
        chartData = byTrack || [];
        return { chartData };
      } else if (currentButton === 'averageForIntroTracks') {
        const orderedTracks = introTracks && introTracks.sort((a, b) => a.order - b.order);
        // console.log('asdf orderedTracks: ', orderedTracks);

        const byTrack =
          orderedTracks?.length &&
          orderedTracks.map((unit) => {
            const thisC = averageLessonRatingByUnitValues?.find((x) => x.id === unit?.id) || null;
            // console.log('asdf thisC: ', thisC);
            return thisC;
          });

        // console.log('asdf averageForIntroTracks: ', byTrack);

        const byTrackData =
          byTrack &&
          byTrack.map((a) => {
            return { x: `${a?.x} (${a?.y})` || 'asdf', y: a?.y || 0 };
          });
        chartData = byTrackData || [];
        return { chartData };
      } else {
        unitData = butonnsS?.find((x) => x.id === currentButton) || null;
        const thisStates = currentButton && lessonRatingByUnit?.[currentButton];
        const thisRating =
          thisStates &&
          thisStates.reduce((lessonRatingMap: { [rating: string]: LessonRatingType[] }, lessonRating) => {
            const thisKey = lessonRating?.rate || `Unknown`;
            (lessonRatingMap[thisKey] = lessonRatingMap?.[thisKey] || []).push(lessonRating);
            return lessonRatingMap;
          }, {});

        chartData = thisRating
          ? Object.entries(thisRating).map(([key, ids]) => ({
              x: key,
              y: ids.length,
            }))
          : null;

        chartTickData = thisRating ? Object.keys(thisRating).map((x) => `${x}`) : null;
        // console.log('thisRating: ', thisRating);
      }
    }

    return { statsData, chartData, chartTickData, unitData };
  }, [lessonRatingsTotal, currentButton, averageLessonRatingByUnitValues, tracks, units, introTracks]);

  // console.log('data: ', data);
  // console.log('butonnsS: ', butonnsS);

  const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(' ');
  };

  useEffect(() => {
    getUsers().then((fUserId) => setUserIds(fUserId));
  }, []);

  useEffect(() => {
    if (userIds) {
      getAllLessonRatings().then(async (preProcessedlessonRatingArray: LessonRatingType[]) => {
        // console.log('All preProcessedlessonRatingArray?.length = ', preProcessedlessonRatingArray?.length);
        const lessonRatingArray = preProcessedlessonRatingArray.filter((lessonRating) =>
          userIds.includes(lessonRating.user),
        );
        // console.log('All lessonRatingArray?.length = ', lessonRatingArray?.length);

        setLessonRatingsTotal(lessonRatingArray);
        lessonRatingArray.sort((a, b) => {
          const date1 = a?.createdAt && a?.createdAt?.toDate ? a?.createdAt?.toDate() : new Date();
          const date2 = b?.createdAt && b?.createdAt?.toDate ? b?.createdAt?.toDate() : new Date();
          if (date1 && date2) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return date2 - date1;
          }
          return -1;
        });
        setSortedLessonRatings(lessonRatingArray);

        // console.log('lessonRatingArray Length: ', lessonRatingArray.length);

        const lessonRatingsPerUnit =
          lessonRatingArray &&
          lessonRatingArray.reduce((lessonRatingMap: { [unitId: string]: LessonRatingType[] }, lessonRating) => {
            const thisKey = lessonRating?.unitId || `${lessonRating?.program}-Lesson_${lessonRating?.lesson}`;
            (lessonRatingMap[thisKey] = lessonRatingMap?.[thisKey] || []).push(lessonRating);
            return lessonRatingMap;
          }, {});
        // console.log('lessonRatingsPerUnit: ', lessonRatingsPerUnit);

        const averageLessonRatingsPerUnit =
          lessonRatingArray &&
          lessonRatingArray.reduce((lessonRatingMap: { [unitId: string]: number[] }, lessonRating) => {
            const thisKey = lessonRating?.unitId || `${lessonRating?.program}-Lesson_${lessonRating?.lesson}`;
            (lessonRatingMap[thisKey] = lessonRatingMap?.[thisKey] || []).push(lessonRating?.rate);
            return lessonRatingMap;
          }, {});

        // console.log('averageLessonRatingsPerUnit: ', averageLessonRatingsPerUnit);

        const averageKeys = !averageLessonRatingByUnit
          ? null
          : Object.keys(averageLessonRatingByUnit).map((lessonKey) => {
              const lessonRatings = averageLessonRatingByUnit?.[lessonKey];

              const averageValue = lessonRatings?.reduce((acc, val) => acc + val, 0) / (lessonRatings?.length || 1);
              const unitTitle = units?.find((unit) => unit.id === lessonKey)?.title || lessonKey;
              const isV2 = unitTitle !== lessonKey;

              return { x: unitTitle, y: Number(averageValue.toFixed(2)), id: lessonKey, isV2 };
            });

        const standDevKeys = !averageLessonRatingByUnit
          ? null
          : Object.keys(averageLessonRatingByUnit).map((lessonKey) => {
              const lessonRatings = averageLessonRatingByUnit?.[lessonKey];

              const standDevValue = getStandardDeviation(lessonRatings);
              const unitTitle = units?.find((unit) => unit.id === lessonKey)?.title || lessonKey;
              const isV2 = unitTitle !== lessonKey;

              return { x: unitTitle, y: Number(standDevValue.toFixed(2)), id: lessonKey, isV2 };
            });

        const sampleLength = !averageLessonRatingByUnit
          ? null
          : Object.keys(averageLessonRatingByUnit).map((lessonKey) => {
              const lessonRatings = averageLessonRatingByUnit?.[lessonKey];

              const sampleLengthValue = lessonRatings?.length || 0;
              const unitTitle = units?.find((unit) => unit.id === lessonKey)?.title || lessonKey;
              const isV2 = unitTitle !== lessonKey;

              return { x: unitTitle, y: Number(sampleLengthValue.toFixed(2)), id: lessonKey, isV2 };
            });

        setLessonRatingByUnit(lessonRatingsPerUnit);
        setAverageLessonRatingByUnit(averageLessonRatingsPerUnit);
        setStandardDevLessonRatingByUnitValues(standDevKeys);
        setSampleNumberFoLessonRatingByUnitValues(sampleLength);

        setAverageLessonRatingByUnitValues(averageKeys);
        // console.log('averageKeys: ', averageKeys);
        // console.log('finished with useEffect');
      });
      getAllUnits().then((fUnits) => setUnits(fUnits));
      getAllTracks().then((fTracks) => {
        // We are going to set all tracks
        setTracks(fTracks);

        // THen we are going to separate the intro Serries from the other tracks
        // const introSeriesTracks = fTracks.filter(
        //   (track) => !!track.isIntroSeries && track.productionStatus === 'PROD' && !track.isArchived  && track.id === "tjYeU6ZmRCKm3BLwPvMV"
        // );

        const introSeriesTracks = fTracks.find((track) => track.id === 'tjYeU6ZmRCKm3BLwPvMV')?.units || null;

        setIntroTracks(introSeriesTracks);

        const notIntroSeriesTracks = fTracks.filter(
          (track) => !track.isIntroSeries && track.productionStatus === 'PROD' && !track.isArchived,
        );
        setPostIntroTracks(notIntroSeriesTracks);
      });
    }
  }, [userIds?.length]);

  if (!lessonRatingsTotal || !sortedLessonRatings || !units) {
    return <div>Loading...</div>;
  }

  const _renderContent = () => {
    if (['thisMonth', 'thisWeek'].includes(currentButton || '')) {
      return (
        <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
          <table className='min-w-full divide-y divide-gray-300'>
            <thead className='bg-gray-50'>
              <tr>
                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  User Id
                </th>

                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  Rating
                </th>
                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  Rating Created At
                </th>
                <th scope='col' className='px-3 py-3.5 text-center text-sm font-semibold text-gray-900'>
                  Unit
                </th>
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200 bg-white'>
              {data?.statsData?.map((lessonRating, idx) => {
                return (
                  <tr key={idx} className='hover:bg-stone-200'>
                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                      <div className='font-medium text-gray-900'>{lessonRating?.user || '-'}</div>
                    </td>
                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                      <div className='font-medium text-gray-900'>{lessonRating?.rate || '-'}</div>
                    </td>

                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                      {lessonRating?.createdAt
                        ? format(lessonRating?.createdAt?.toDate(), 'dd MMM yyyy hh:mm aa')
                        : '-'}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      <div className='font-medium text-gray-900'>
                        {!units?.length
                          ? null
                          : units?.find((t2: UnitType) => t2.id === lessonRating?.unitId)?.title || '-'}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    } else if (currentButton === 'byTracks' && averageLessonRatingByUnitValues?.length) {
      return (
        <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
          <table className='min-w-full divide-y divide-gray-300'>
            <thead className='bg-gray-50'>
              <tr>
                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  Track
                </th>

                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  in Productions
                </th>

                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  Units (Click to show rating)
                </th>
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200 bg-white'>
              {tracks?.map((track, idx) => {
                return (
                  <tr key={idx} className='hover:bg-stone-200'>
                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                      <div className='font-medium text-gray-900'>{track?.title || '-'}</div>
                    </td>
                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                      <div className='font-medium text-gray-900'>{track?.productionStatus || '-'}</div>
                    </td>

                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                      <div className='ml-4 flex items-center flex-col '>
                        {userIds?.length && standardDevLessonRatingByUnitValues?.length && track?.units?.length ? (
                          <>
                            {track.units?.map(({ id }, idx) => {
                              const unit = units?.find((fU) => fU.id === id);
                              return (
                                <button
                                  key={id}
                                  className='text-gray-500 p-1 m-1 border rounded hover:bg-gray-100'
                                  onClick={() => {
                                    const unitData = butonnsS?.find((x) => x.id === id) || null;
                                    const thisStates = id && lessonRatingByUnit?.[id];
                                    const thisRating =
                                      thisStates &&
                                      thisStates.reduce(
                                        (lessonRatingMap: { [rating: string]: LessonRatingType[] }, lessonRating) => {
                                          const thisKey = lessonRating?.rate || `Unknown`;
                                          (lessonRatingMap[thisKey] = lessonRatingMap?.[thisKey] || []).push(
                                            lessonRating,
                                          );
                                          return lessonRatingMap;
                                        },
                                        {},
                                      );
                                    const chartData = thisRating
                                      ? Object.entries(thisRating).map(([key, ids]) => ({
                                          x: key,
                                          y: ids.length,
                                        }))
                                      : null;

                                    const chartTickData = thisRating
                                      ? Object.keys(thisRating).map((x) => `${x}`)
                                      : null;
                                    // console.log('thisRating: ', thisRating);
                                    setPopupData({
                                      unitData,
                                      chartData,
                                      chartTickData,
                                    });
                                    setShowRating(true);
                                  }}
                                >
                                  Unit {idx + 1} {unit?.title} - Average Rating{' '}
                                  {averageLessonRatingByUnitValues?.find((x) => x.id === unit?.id)?.y || '-'}
                                  {'\n'}
                                  Standard Dev-{' '}
                                  {standardDevLessonRatingByUnitValues?.find((x) => x.id === unit?.id)?.y || '-'}
                                  {'\n'}
                                  Number Of Samples-{' '}
                                  {sampleNumberFoLessonrRatingByUnitValues?.find((x) => x.id === unit?.id)?.y || '-'}
                                </button>
                              );
                            })}
                          </>
                        ) : (
                          <div className='text-gray-500'>-</div>
                        )}
                      </div>
                    </td>
                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                      <button
                        key={`button-${idx}`}
                        className='text-gray-500 p-1 m-1 border rounded hover:bg-gray-100'
                        onClick={() => {
                          // const chartTickData = s;
                          // setPopupData({
                          //   unitData,
                          //   chartData,
                          //   chartTickData,
                          // });
                          // setShowRating(true);
                        }}
                      >
                        show average rating
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    } else if (currentButton === 'byPreTracks') {
      return (
        <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
          <table className='min-w-full divide-y divide-gray-300'>
            <thead className='bg-gray-50'>
              <tr>
                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  Program
                </th>

                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  Lesson
                </th>
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200 bg-white'>
              {preTrackItems &&
                preTrackItems?.map((preTrack, idx) => {
                  // This is for PreTrtack Items... meaning...
                  // anything of the old system iin equa that was like
                  // "Foundation 1" or Foundation 2"

                  return (
                    <tr key={idx} className='hover:bg-stone-200'>
                      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                        <div className='font-medium text-gray-900'>{preTrack?.name || '-'}</div>
                      </td>
                      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                        <div className='font-medium text-gray-900'>{preTrack?.lesson || '-'}</div>
                      </td>

                      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                        <div className='ml-4 flex items-center flex-col '>
                          <button
                            className='text-gray-500 p-1 m-1 border rounded hover:bg-gray-100'
                            onClick={() => {
                              const thisStates = preTrack?.id && lessonRatingByUnit?.[preTrack?.id];
                              const thisRating =
                                thisStates &&
                                thisStates.reduce(
                                  (lessonRatingMap: { [rating: string]: LessonRatingType[] }, lessonRating) => {
                                    const thisKey = lessonRating?.rate || `Unknown`;
                                    (lessonRatingMap[thisKey] = lessonRatingMap?.[thisKey] || []).push(lessonRating);
                                    return lessonRatingMap;
                                  },
                                  {},
                                );
                              const chartData = thisRating
                                ? Object.entries(thisRating).map(([key, ids]) => ({
                                    x: key,
                                    y: ids.length,
                                  }))
                                : null;

                              const chartTickData = thisRating ? Object.keys(thisRating).map((x) => `${x}`) : null;

                              setPopupData({
                                unitData: preTrack,
                                chartData,
                                chartTickData,
                              });
                              setShowRating(true);
                            }}
                          >
                            Show Rating
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      );
    } else if (
      currentButton === 'averageForIntroTracks' &&
      data?.chartData?.length &&
      !data?.chartData?.some((chartData) => !chartData)
    ) {
      // const byTrack = averageLessonRatingByUnitValues
      //   ?.filter((x) => introTracks && introTracks.some((track) => track.units.some((unit) => unit?.id === x?.id)))
      //   .sort((a, b) => b.y - a.y);
      // console.log('introSeriesTracks: ', byTrack);

      return (
        <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
          <BarChart
            chartData={[
              {
                data: data?.chartData || [],
                fill: 'lightblue',
                name: 'Average Lesson Ratings',
              },
            ]}
            chartTitle={'Average Lesson Ratings'}
            domainPadding={20}
            tooltipsEnabled
            xLabel={'Intro Series Lesson'}
            max={5}
            yLabel={'AverageRating'}
            verticalLabel
          />
        </div>
      );
    } else if (
      currentButton === 'averageForPostIntroTracks' &&
      data?.chartData?.length &&
      !data?.chartData?.some((chartData) => !chartData)
    ) {
      return (
        <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
          <BarChart
            chartData={[
              {
                data: data?.chartData || [],
                fill: 'lightblue',
                name: 'Average Lesson Ratings',
              },
            ]}
            chartTitle={'Average Lesson Ratings'}
            domainPadding={20}
            tooltipsEnabled
            xLabel={'Post Intro Series Lessons'}
            max={5}
            yLabel={'AverageRating'}
            verticalLabel
          />
        </div>
      );
    } else if (
      currentButton === 'averageByTracks' &&
      data?.chartData?.length &&
      !data?.chartData?.some((chartData) => !chartData)
    ) {
      return (
        <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
          <BarChart
            chartData={[
              {
                data: data?.chartData || [],
                fill: 'lightblue',
                name: 'Average Lesson Ratings',
              },
            ]}
            chartTitle={'Average Lesson Ratings'}
            domainPadding={20}
            tooltipsEnabled
            xLabel={'Lesson'}
            max={5}
            yLabel={'AverageRating'}
            verticalLabel
          />
        </div>
      );
    } else if (data?.chartData?.length && !data?.chartData?.some((chartData) => !chartData)) {
      return (
        <BarChart
          chartData={[
            {
              data: data?.chartData || [],
              fill: 'lightblue',
              name: 'Whos Completed what',
            },
          ]}
          chartTitle={data?.unitData?.name || 'Some Lesson'}
          tickValues={data?.chartTickData || []}
          domainPadding={20}
          tooltipsEnabled
          xLabel={'Rating'}
          yLabel={'# of Votes'}
          verticalLabel
        />
      );
    }
    return null;
  };
  return (
    <>
      {showRating ? <LessonAnalysisPopupRating data={popupData} onPressClose={() => setShowRating(false)} /> : null}
      <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
        {[...buttons].map((button: { name: string; id: string; isV2?: boolean }) => (
          <th key={button?.name}>
            <div className='flex justify-center'>
              <button
                onClick={() => {
                  setCurrentButton(button?.id);
                }}
                className={classNames(
                  button.id === currentButton
                    ? 'border border-blue rounded-20 block rounded-lg px-5 py-2 text-base font-semibold leading-7 text-gray-900  bg-gray-900 text-white'
                    : 'border border-blue rounded-20 block rounded-lg px-5 py-2 text-base font-semibold leading-7 text-gray-900',
                )}
              >
                {button.name}
              </button>
            </div>
          </th>
        ))}

        <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
          <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
            <thead className='bg-gray-50'>
              <tr>
                <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  Total LessonRatings {lessonRatingsTotal?.length} Accross {userIds?.length} Users
                </th>
              </tr>
            </thead>
          </div>

          {_renderContent()}
        </div>
      </div>
    </>
  );
};

export default LessonAnalysisForUsersWhoCompletedEverything;
