import React, { useEffect } from 'react';
import AudioNodeList from '../../components/audioNodes/AudioNodeList';
import { useAppContext } from '../../contexts/appContext';

const AudioNodePage = () => {
  const { setBreadcrumbPaths } = useAppContext();
  useEffect(() => {
    setBreadcrumbPaths([{ label: 'Tracks', path: '/tracks' }]);
  }, [setBreadcrumbPaths]);
  return (
    <>
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-xl font-semibold text-gray-900'>AudioNodes</h1>
        </div>
        <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'></div>
      </div>
      <h2 className='text-l font-semibold text-gray-900 mt-5'>Available Tracks</h2>
      <AudioNodeList />
    </>
  );
};

export default AudioNodePage;
